exports.components = {
  "component---src-page-types-athlete-page-js": () => import("./../../../src/page-types/athlete-page.js" /* webpackChunkName: "component---src-page-types-athlete-page-js" */),
  "component---src-page-types-event-pro-js": () => import("./../../../src/page-types/event-pro.js" /* webpackChunkName: "component---src-page-types-event-pro-js" */),
  "component---src-page-types-page-pro-js": () => import("./../../../src/page-types/page-pro.js" /* webpackChunkName: "component---src-page-types-page-pro-js" */),
  "component---src-page-types-post-pro-js": () => import("./../../../src/page-types/post-pro.js" /* webpackChunkName: "component---src-page-types-post-pro-js" */),
  "component---src-page-types-results-page-js": () => import("./../../../src/page-types/results-page.js" /* webpackChunkName: "component---src-page-types-results-page-js" */),
  "component---src-page-types-team-page-js": () => import("./../../../src/page-types/team-page.js" /* webpackChunkName: "component---src-page-types-team-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */)
}

