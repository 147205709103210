import('./src/scss/pro/index.scss');

export const onRouteUpdate = () => {
	document.getElementsByTagName('body')[0]?.classList.remove('nav-expanded');
	const scrollToTopRoutes = ['/latest', '/watch', '/athlete', '/results']
	const { pathname } = window.location

	if (scrollToTopRoutes.some(route => pathname.includes(route))) {
		setTimeout(() => {
			window.scrollTo({
				top: -56,
				behavior: 'instant'
			})
		}, 1)
	}
}

export const shouldUpdateScroll = ({
	routerProps: { location }
}) => {
	return false
}
